import React from "react";
import { Label } from "flowbite-react";
import Slider from "@mui/material/Slider";
import Tooltip from '@mui/material/Tooltip';
import { HiInformationCircle, HiOutlineInformationCircle } from "react-icons/hi";


interface BestOfSliderProps {
    bestOf: number;
    setBestOf: (bestOf: number) => void;
}

export const BestOfSlider = ({ bestOf, setBestOf }: BestOfSliderProps) => {

    const STEP = 1;
    const MIN = 1;
    const MAX = 20;

    const tooltipText = "Generates multiple completions server-side, and displays only the best. Since it acts as a multiplier on the number of completions, this parameters can eat into your token quota very quickly - use caution!"; 

    return (
            <div className="py-1 px-2">
                <div className="px-2 block flex items-center">
                    <Label
                        htmlFor="bestOf"
                        value="Best of"
                        className="shrink-0"

                    />
                    <Tooltip title={tooltipText} placement='top' enterTouchDelay={0} arrow>
                        <div className="ml-2">
                            <HiInformationCircle />
                        </div>
                    </Tooltip>
                </div>
                <Slider aria-label="BestOf"
                    min={MIN}
                    max={MAX}
                    step={STEP}
                    value={bestOf}
                    onChange={(e, value) => setBestOf(value as number)}
                    size="small"
                    valueLabelDisplay="auto"
                />
            </div>
    );
}