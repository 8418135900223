import React from 'react';
import { Badge, Label, Select } from 'flowbite-react';
import { IPrompt } from '../../../interfaces/interfaces';
import { TemperatureSlider } from './TemperatureSlider';
import { MaximumLengthSlider } from './MaximumLengthSlider';
import { TopPSlider } from './TopPSlider';
import { FrequencyPenaltySlider } from './FrequencyPenaltySlider';
import { PresencePenaltySlider } from './PresencePenaltySlider';
import { BestOfSlider } from './BestOfSlider';

interface RightBarProps {
    prompt: IPrompt;
    setPrompt: (prompt: IPrompt) => void;
    showRightBarInMobile: boolean;
    onToggleRightBarInMobile: () => void;
}

export const RightBar = ({ prompt, setPrompt, showRightBarInMobile, onToggleRightBarInMobile }: RightBarProps) => {



    return (
        <div className={`relative md:w-1/6 bg-white px-2 md:bg-white  transition-transform  duration-300 ${showRightBarInMobile ? 'w-5/6 -translate-x-full' : 'w-0 '}  shrink-0 md:block`}>
            {/* <div className="flex justify-around items-center">
                <button className="py-3  px-2 border-b-4 font-semibold text-indigo-500 border-b-indigo-500">
                    Text
                </button>
                <button className='py-3 px-2 hover:text-indigo-500 hover:border-b-2 hover:border-indigo-500'>
                    Image
                </button>
                <button className='py-3 px-2 hover:text-indigo-500 hover:border-b-2 hover:border-indigo-500'>
                    Code
                </button>
            </div> */}

            {/* <div className="py-1 px-2">

                <div className="mb-2 px-2 block">
                    <Label
                        htmlFor="model"
                        value="Model"
                    />
                </div>
                <Select
                    id="model"
                    required={true}
                >
                    <option>
                        text-da-vinci003
                    </option>
                    <option disabled>
                        text-da-vinci002
                    </option>
                    <option disabled>
                        text-da-vinci001
                    </option>
                </Select>
            </div> */}
            <TemperatureSlider temperature={prompt.temperature} setTemperature={(temperature: number) => { setPrompt({ ...prompt, temperature }) }} />
            <MaximumLengthSlider maximumLength={prompt.maximumLength} setMaximumLength={(maximumLength: number) => { setPrompt({ ...prompt, maximumLength }) }} />
            <TopPSlider topP={prompt.topP} setTopP={(topP: number) => { setPrompt({ ...prompt, topP }) }} />
            <FrequencyPenaltySlider frequencyPenalty={prompt.frequencyPenalty} setFrequencyPenalty={(frequencyPenalty: number) => { setPrompt({ ...prompt, frequencyPenalty }) }} />
            <PresencePenaltySlider presencePenalty={prompt.presencePenalty} setPresencePenalty={(presencePenalty: number) => { setPrompt({ ...prompt, presencePenalty }) }} />
            <BestOfSlider bestOf={prompt.bestOf} setBestOf={(bestOf: number) => { setPrompt({ ...prompt, bestOf }) }} />
            <div className={`absolute top-0 left-0 px-1 bg-white transform -translate-x-full z-50 ${showRightBarInMobile ? '' : 'hidden'}`}>
                <button className="text-gray-600 hover:text-gray-800 focus:outline-none focus:shadow-outline-blue" onClick={onToggleRightBarInMobile}>
                    <svg className="h-8 w-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                </button>
            </div>
         
        </div>
    );
}