import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Prompt } from "./Prompt/Prompt";
import { Header } from "../Header/Header";
import { LeftBar } from "./LeftBar/LeftBar";
import { RightBar } from "./RightBar/RightBar";
import { createConversation, createPrompt, getConversationsByOwner, getPromptsByOwner } from "../../services/ChatAPI";
import { IConversation, IPrompt } from "../../interfaces/interfaces";
import { useAuth } from "../../firebase/context";
import { useTitle } from "react-use";
import { PricingModal } from "../PricingModal/PricingModal";

export function PromptPage() {

    const { promptId } = useParams();
    const navigate = useNavigate();

    const { user, loading, showPricingModal } = useAuth();


    const [prompts, setPrompts] = useState<IPrompt[]>([]);
    const [currentPrompt, setCurrentPrompt] = useState<IPrompt>();


    const [promptsLoading, setPromptsLoading] = useState(true);
    const [newPromptLoading, setNewPromptLoading] = useState(false);


    async function createAndNavigateToPrompt(prompt?: IPrompt) {
        const newPrompt = await createPrompt({
            title: prompt?.title || 'Untitled Prompt',
            owner: user?.uid as string,
            temperature: prompt?.temperature || 0.7,
            topP: prompt?.topP || 1,
            maximumLength: prompt?.maximumLength || 1000,
            frequencyPenalty: prompt?.frequencyPenalty || 0,
            presencePenalty: prompt?.presencePenalty || 0,
            bestOf: prompt?.bestOf || 1,
            prompt: prompt?.prompt || '',
            doNotUpdate: prompt?.doNotUpdate || false,
        });
        navigate(`/prompt/${newPrompt.uid}`);
        setCurrentPrompt(newPrompt);
    }

    useEffect(() => {
        async function updateCurrentConversation() {
            if (prompts.length === 0 && !promptsLoading) {
                await createAndNavigateToPrompt();
            } else if (!promptId && prompts.length > 0 && !promptsLoading) {
                navigate(`/prompt/${prompts[0].uid}`);
                setCurrentPrompt(prompts[0]);
            } else if (!promptsLoading) {
                const prompt = prompts.find(c => c.uid === promptId);
                if (!prompt) {
                    navigate(`/prompt/`);
                }
                setCurrentPrompt(prompt);
            }
        }
        updateCurrentConversation();
    }, [promptId, prompts, promptsLoading]);

    useTitle(currentPrompt?.title + ' - GenerativeDay' || "Chat - GenerativeDay");

    // Callback function to set the conversationsLoading state to false when the conversations are done conversationsLoading
    const onPromptsLoaded = (lastPrompt: IPrompt | undefined) => {
        if(lastPrompt && lastPrompt.output && lastPrompt.output.length){
            setNewPromptLoading(false);
        } else if(lastPrompt && lastPrompt.output && !lastPrompt.output.length){
            setNewPromptLoading(true);
        }
        // setNewPromptLoading(false);
        setPromptsLoading(false);
    }

    useEffect(() => {
        const unsubscribe = getPromptsByOwner(user?.uid as string, setPrompts, onPromptsLoaded);
        return () => unsubscribe();
    }, []);
    

    const [showLeftBarInMobile, setShowLeftBarInMobile] = useState(false);
    const [showRightBarInMobile, setShowRightBarInMobile] = useState(false);

    const onToggleLeftBarInMobile = () => {
        setShowLeftBarInMobile(!showLeftBarInMobile);
    }

    const onToggleRightBarInMobile = () => {
        setShowRightBarInMobile(!showRightBarInMobile);
    }

    return (<>
        <Header onToggleLeftBarInMobile={onToggleLeftBarInMobile} />
        <main className="flex min-h-0 h-full flex-grow overflow-x-hidden">
            {showPricingModal && <PricingModal />}
            {(currentPrompt && !loading) ? (<>
                <LeftBar prompts={prompts} createAndNavigateToPrompt={createAndNavigateToPrompt} showLeftBarInMobile={showLeftBarInMobile} setShowLeftBarInMobile={setShowLeftBarInMobile} />
                <Prompt prompt={currentPrompt} createAndNavigateToPrompt={createAndNavigateToPrompt} setPrompt={setCurrentPrompt} newPromptLoading={newPromptLoading} setNewPromptLoading={setNewPromptLoading} onToggleRightBarInMobile={onToggleRightBarInMobile} />
                <RightBar prompt={currentPrompt} setPrompt={setCurrentPrompt} showRightBarInMobile={showRightBarInMobile} onToggleRightBarInMobile={onToggleRightBarInMobile} />
            </>) : <div className="w-full  h-full shrink-0 flex flex-col justify-between relative">
                <div className="flex-grow flex items-center justify-center">
                    <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-blue-500"></div>
                </div>
            </div>}

        </main>
    </>);
}