import { Dropdown } from "flowbite-react";
import React from "react";
import { Link, useParams } from "react-router-dom";
import { IPrompt } from "../../../interfaces/interfaces";
import { DeletePromptModal } from "./DeletePromptModal";
import { RxDiscordLogo } from "react-icons/rx";

interface LeftBarProps {
    prompts: IPrompt[];
    showLeftBarInMobile: boolean;
    setShowLeftBarInMobile: (show: boolean) => void;
    createAndNavigateToPrompt: (prompt?: IPrompt) => Promise<void>;
}

export const LeftBar = ({ prompts, showLeftBarInMobile, setShowLeftBarInMobile, createAndNavigateToPrompt }: LeftBarProps) => {

    

    const { promptId } = useParams();

    const [showDeleteModal, setShowDeleteModal] = React.useState(false);
    const [selectedPrompt, setSelectedPrompt] = React.useState<IPrompt | null>(null);

    const createNewPrompt = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        await createAndNavigateToPrompt();
        setShowLeftBarInMobile(false);
    }



    return (
        <nav className={` md:w-1/6  bg-gray-100 relative transition-width  duration-300   h-full ease-in-out md:transition-none    shrink-0 ${showLeftBarInMobile ? 'w-5/6' : 'w-0 '}  md:block md:bg-white `}>
            {/* <div className="py-4 px-2">
                <div className="flex items-center  px-2 py-1 font-medium text-gray-700 hover:text-gray-900 focus:outline-none focus:shadow-outline-blue">
                    <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0zM10 7v3m0 0v3m0-3h3m-3 0H7"></path></svg>
                    <span className="ml-2">Search</span>
                </div>
                <div className="flex items-center px-2 py-1 font-medium text-gray-700 hover:text-gray-900 focus:outline-none focus:shadow-outline-blue">
                    <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                    <span className="ml-2">Updates</span>
                </div>
            </div> */}
            <div className="relative h-full overflow-hidden">
                <div className="pb-4 pl-1 h-full">
                    <div className="flex justify-between items-center">
                        <h3 className="font-bold text-sm  text-gray-800 px-2 py-1">History</h3>
                        <button className="text-gray-600 hover:text-gray-800 focus:outline-none focus:shadow-outline-blue" onClick={createNewPrompt} id="new-prompt-button">
                            <svg className="h-8 w-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9"></path></svg>
                        </button>
                    </div>
                    <div className="overflow-auto max-h-full  pb-8">
                        <ul className="mt-2 pl-3 pb-8">
                            {prompts.map((prompt) => (
                                <li key={prompt.uid} className={`px-2 py-1 text-xs font-medium text-gray-700 hover:text-gray-900 flex justify-between items-center focus:outline-none focus:shadow-outline-blue ${promptId === prompt.uid ? 'font-bold' : ''}`}>
                                    <Link to={`/prompt/${prompt.uid}`} onClick={() => setShowLeftBarInMobile(false)} className="pr-2 prompt-link">
                                        {prompt.title}
                                    </Link>
                                    <Dropdown
                                        arrowIcon={false}
                                        inline={true}
                                        className="font-normal"
                                        label={<svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"></path></svg>}
                                    >
                                        {/* <Dropdown.Header>
                                        <span className="block text-sm">
                                            {prompt.title}
                                        </span>
                                    </Dropdown.Header> */}
                                        {/* <Link to={`/conversation/${prompt.uid}`}>
                                        <Dropdown.Item>
                                            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"></path></svg>
                                            View
                                        </Dropdown.Item>
                                    </Link> */}
                                        {/* <Dropdown.Item>
                                        <HiOutlineStar />
                                        Add to favorites
                                    </Dropdown.Item> */}
                                        {/* <Dropdown.Item onClick={() => {
                                        setSelectedPrompt(prompt);
                                        // console.log(conversation, selectedConversation)
                                        setShowRenameModal(true);
                                    }}>
                                        <HiOutlinePencilAlt />
                                        Rename
                                    </Dropdown.Item> */}
                                        <Dropdown.Item onClick={() => {
                                            setSelectedPrompt(prompt);
                                            // console.log(conversation, selectedConversation)
                                            setShowDeleteModal(true);

                                        }}>
                                            {/* <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path></svg> */}
                                            Delete
                                        </Dropdown.Item>
                                    </Dropdown>

                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
            {selectedPrompt && <DeletePromptModal show={showDeleteModal} setShow={setShowDeleteModal} prompt={selectedPrompt} setSelectedPrompt={setSelectedPrompt} prompts={prompts} />}
            <div className="absolute bottom-0 left-0 pb-2 pl-2 hidden md:block md:bg-white md:w-full">
                <a href="https://discord.gg/MpN8rXhu" className="flex items-center text-sm font-medium text-gray-700 hover:text-gray-900 focus:outline-none focus:shadow-outline-blue">
                    <RxDiscordLogo className="mr-1" />
                    Support
                </a>
            </div>
            <div className={`absolute top-0 right-0 pb-2 pl-2 bg-white w-1/6 transform translate-x-full z-50 ${showLeftBarInMobile ? '' : 'hidden'}`}>
                <button className="text-gray-600 hover:text-gray-800 focus:outline-none focus:shadow-outline-blue" onClick={() => setShowLeftBarInMobile(false)}>
                    <svg className="h-8 w-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                </button>
            </div>

        </nav>
    );
};