import { Avatar } from "flowbite-react";
import React from "react";
import { IChatMessage } from "../../../interfaces/interfaces";



export const ChatMessage = ({ text, author } : IChatMessage) => {


    return (
        <div className="py-2 even:bg-gray-100">
            <div className="px-2 flex items-start ">
                <Avatar alt="User settings" img={author.photoURL ?? undefined} rounded={true} className="mr-3"/>
                <div className="flex-1">
                    <p className="text-xs font-medium text-gray-700">{author.displayName}</p>
                    <p className="text-xs text-gray-900 whitespace-pre-wrap	">{text}</p>
                </div>
            </div>
        </div>
    );
};