import React from 'react';
import { Badge, Navbar } from 'flowbite-react';
import { useAuth } from '../../firebase/context';
import { HeaderLoginButton } from './HeaderLoginButton';
import { HeaderUserButton } from './HeaderUserButton';


interface HeaderProps {
    onToggleLeftBarInMobile: () => void;
}

export const Header = ({
    onToggleLeftBarInMobile
}: HeaderProps) => {

    const { user, logout } = useAuth();

    return (
        <Navbar
            fluid={true}
            rounded={true}

        >

            {/* <Navbar.Brand >
                <Navbar.Toggle onClick={onToggleLeftBarInMobile} />

                <img src="/logo.png" alt="GenerativeDay" className="h-5" />
                <Badge
                    color="warning"
                    className="ml-2"
                >
                    Beta
                </Badge>
            </Navbar.Brand> */}
            {user ?
                <>
                    <Navbar.Toggle onClick={onToggleLeftBarInMobile} />
                    <HeaderUserButton user={user} logout={logout} />
                </>
                :
                <HeaderLoginButton />
            }

        </Navbar>
    );
};