import React from "react";
import { Label } from "flowbite-react";
import Slider from "@mui/material/Slider";
import Tooltip from "@mui/material/Tooltip";
import { HiInformationCircle, HiOutlineInformationCircle } from "react-icons/hi";

interface TemperatureSliderProps {
    temperature: number;
    setTemperature: (temperature: number) => void;
}

export const TemperatureSlider = ({ temperature, setTemperature }: TemperatureSliderProps) => {

    const STEP = 0.01;
    const MIN = 0;
    const MAX = 1;

    const tooltipText = "Controls randomness: Lowering results in less random completions. As the temperature approaches zero, the model will become deterministic and repetitive.";


    return (
            <div className="py-1 px-2">
                <div className="px-2 block flex items-center">
                    <Label
                        htmlFor="temperature"
                        value="Temperature"
                    />
                    <Tooltip title={tooltipText} placement='top' enterTouchDelay={0} arrow>
                        <div className="ml-2">
                            <HiInformationCircle />
                        </div>
                    </Tooltip>
                </div>
                <Slider aria-label="Temperature"
                    min={MIN}
                    max={MAX}
                    step={STEP}
                    value={temperature}
                    onChange={(e, value) => setTemperature(value as number)}
                    size="small"
                    valueLabelDisplay="auto"
                />
            </div>
    );
}