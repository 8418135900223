import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Header } from './components/Header/Header';
import { LeftBar } from './components/ConversationPage/LeftBar/LeftBar';
import { Chat } from './components/ConversationPage/Chat/Chat';
import { RightBar } from './components/ConversationPage/RightBar/RightBar';
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import { useAuth } from './firebase/context';
import { Login } from './components/Login/Login';
import { ConversationPage } from './components/ConversationPage/ConversationPage';
import { PromptPage } from './components/PromptPage/PromptPage';
import { ToastContainer } from 'react-toastify';
function App() {

  const { user } = useAuth();

  return (
    <div className="flex flex-col h-full">
      <Router>

        {user ?

          <Routes>
            <Route path="/" element={<Navigate to="/prompt/" />} />
            <Route path='/prompt/:promptId?' element={<PromptPage />} />
            <Route path='/conversation/:conversationId?' element={<ConversationPage />} />
          </Routes>
          : <Login />
        }
        <ToastContainer autoClose={2000} theme="light" bodyClassName="text-sm" />
      </Router>
    </div>
  );
}

export default App;
