import React from "react";
import { useAuth } from "../../firebase/context";

export const HeaderLoginButton = () => {

    const { signInWithGoogle } = useAuth();

    return (
        <button onClick={signInWithGoogle} className='py-1 px-4 transition-all border-2 border-indigo-500 bg-indigo-500 text-white rounded hover:bg-transparent hover:text-blue-600 hover:border-blue-600 '>
            Login with Google
        </button>
    );
};