import { Auth, browserLocalPersistence, getAuth, GoogleAuthProvider, onAuthStateChanged, onIdTokenChanged, signInWithPopup, signInWithRedirect, User as FirebaseUser } from "firebase/auth";
import { collection, doc, DocumentData, DocumentSnapshot, getFirestore, onSnapshot, setDoc, Timestamp } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import React, { Context, createContext, useContext, useEffect, useRef, useState } from "react";
import firebaseApp from ".";
import { launchSubscription, watchRefreshTokensNotifications, watchTokens } from "../services/ChatAPI";

const auth: Auth = getAuth(firebaseApp);
const db = getFirestore(firebaseApp);
const functions = getFunctions(firebaseApp, "europe-west3");

export const AuthContext: Context<IAuthContext> = createContext<IAuthContext>({
    user: null,
    auth,
    signInWithGoogle: () => { },
    logout: () => { },
    redirectToBillingSettings: () => { },
    loading: false,
    showPricingModal: false,
    isFreeTier: false,
});

interface IAuthContext {
    user: null | FirebaseUser;
    auth: Auth;
    signInWithGoogle: () => void;
    logout: () => void;
    redirectToBillingSettings: () => void;
    loading: boolean;
    showPricingModal: boolean;
    isFreeTier: boolean;
}

interface IAuthProviderProps {
    children: React.ReactNode;
}


export const AuthProvider = ({ children }: IAuthProviderProps) => {
    const [user, setUser] = useState<null | FirebaseUser>(null);
    const [loading, setLoading] = useState(true);
    const [isLoggingOut, setIsLoggingOut] = useState(false);
    const [tokens, setTokens] = useState<number>(0);
    const [isFreeTier, setIsFreeTier] = useState<boolean>(false);

    const [showPricingModal, setShowPricingModal] = useState(false);
    // lastCommited is used to store the last time user claims where updated.
    const [lastCommitted, setLastCommitted] = useState<Timestamp | null>(null);
    const lastCommittedRef = useRef<Timestamp | null>(null);


    useEffect(() => {
        if (isLoggingOut) {
            setIsLoggingOut(false);
            setUser(null);
        }
    }, [isLoggingOut])

    useEffect(() => {
        setLoading(false);

        if (user) {
            // console.log("notifications effect triggered")
            // const unsubscribeNotifications = watchRefreshTokensNotifications(user.uid, () => {
            //     console.log("refresh tokens");
            //     user.getIdToken(true);
            // });

            const unsubscribeUserClaims = listenToClaims();
            const unsubscribeTokens = watchTokens(user.uid, (tokens) => {
                setTokens(tokens);
            });

            return () => {
                unsubscribeUserClaims();
                unsubscribeTokens();
            }
        }
    }, [user])

    useEffect(() => {


        const unsubscribeIdTokenChanged = onIdTokenChanged(auth, (newUser) => {
            if (newUser) {
                setUser(newUser);

                console.log("id", newUser.uid)


                newUser.getIdTokenResult(true).then((idTokenResult) => {
                    // console.log('claims from onIdTokenChanged:', idTokenResult.claims);

                    // console.log("!idTokenResult.claims.stripeRole",!idTokenResult.claims.stripeRole)
                    // console.log("idTokenResult.claims.stripeRole !== 'individualPlan'",idTokenResult.claims.stripeRole !== "individualPlan")
                    // console.log("idTokenResult.claims.stripeRole !== 'proPlan'",idTokenResult.claims.stripeRole !== "proPlan")
                    // console.log("idTokenResult.claims.stripeRole !== 'individualPlan' || idTokenResult.claims.stripeRole !== 'proPlan'",idTokenResult.claims.stripeRole !== "individualPlan" || idTokenResult.claims.stripeRole !== "proPlan")

                    const allowedStripeRoles = ["individualPlan", "proPlan"];

                    console.log(idTokenResult.claims)
                    if (!idTokenResult.claims.stripeRole || !allowedStripeRoles.includes(idTokenResult.claims.stripeRole)) {
                        console.log(idTokenResult.claims.stripeRole)
                        // console.log(allowedStripeRoles.includes(idTokenResult.claims.stripeRole))
                        if (idTokenResult.claims.freeTier) {
                            console.log("Free tier")
                            setIsFreeTier(true);
                            setShowPricingModal(false);
                            return;
                        } else if (idTokenResult.claims.hasOwnProperty("freeTier")) {
                            console.log("No stripe role");
                            setLoading(true);
                            setShowPricingModal(true);
                            // launchSubscription(newUser.uid);
                        }
                    } else {
                        console.log("Stripe role", idTokenResult.claims.stripeRole)
                        setIsFreeTier(false);
                        setLoading(false);
                        setShowPricingModal(false)
                    }
                });
            }
            // console.log("Auth state changed", user)




        });


        return () => {
            unsubscribeIdTokenChanged();
        }

    }, []);

    useEffect(() => {
        console.log('lastCommitted from useEffect', lastCommitted)
    }, [lastCommitted])

    const onNewClaims = (snapShot: DocumentSnapshot): void => {
        console.log('onNewClaims', snapShot);
        const data = snapShot.data();
        console.log('New claims doc\n', data);
        if (data?._lastCommitted) {
            console.log('data?._lastCommitted', data?._lastCommitted);
            console.log('state Last Commited: ', lastCommittedRef.current);
                // Force a refresh of the user's ID token
                console.log('Refreshing token');
                user!.getIdToken(true);
            
            lastCommittedRef.current = data._lastCommitted;
            setLastCommitted(data._lastCommitted);
        }
    };

    // const onNewClaims = (snapShot: DocumentSnapshot): void => {
    //     console.log("onNewClaims", snapShot)
    //     const data = snapShot.data();
    //     console.log('New claims doc\n', data)
    //     if (data?._lastCommitted) {
    //         console.log('data?._lastCommitted', data?._lastCommitted)
    //         console.log('state Last Commited: ', lastCommitted)
    //         if (lastCommitted &&
    //               !data._lastCommitted.isEqual(lastCommitted)) {
    //             // Force a refresh of the user's ID token
    //             console.log('Refreshing token')
    //             user!.getIdToken(true)
    //         }
    //         setLastCommitted(data._lastCommitted);
    //     }
    // }

    const listenToClaims = () => {
        console.log(user)
        const unsubscribe = onSnapshot(doc(db, 'user-claims', user!.uid), onNewClaims);
        return unsubscribe;
    }


    const signInWithGoogle = async () => {
        const provider = new GoogleAuthProvider();
        try {
            setLoading(true);
            await auth.setPersistence(browserLocalPersistence);
            const result = await signInWithPopup(auth, provider);
            // const result = await signInWithRedirect(auth, provider);
            console.log(result);
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };




    const redirectToBillingSettings = async () => {
        const createPortalLink = httpsCallable(functions, 'ext-firestore-stripe-payments-createPortalLink') as any;
        setLoading(true);
        const { data } = await createPortalLink({ returnUrl: window.location.origin });
        window.location.assign(data.url);
    }

    const logout = async () => {
        try {
            setIsLoggingOut(true);
            await auth.signOut();
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <AuthContext.Provider value={{ user, auth, signInWithGoogle, logout, redirectToBillingSettings, loading, showPricingModal, isFreeTier }}>{children}</AuthContext.Provider>
    );
};

export function useAuth(): IAuthContext {
    return useContext(AuthContext);
}