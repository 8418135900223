import React from 'react';
import { Modal } from 'flowbite-react';
import { useAuth } from '../../firebase/context';
import { launchSubscription } from '../../services/ChatAPI';

export const PricingModal = () => {

    const { user } = useAuth();
    const [loading, setLoading] = React.useState(false);

    const handleMonthlyClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setLoading(true)
        launchSubscription(user?.uid as string, 'monthly');
    }

    const handleYearlyClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setLoading(true)
        launchSubscription(user?.uid as string, 'yearly');
    }

    return <Modal
        show={true}
        size="8xl"
        onClose={() => console.log('close')}
    >
        {/* <Modal.Header>
      Small modal
    </Modal.Header> */}
        <Modal.Body>
            <section className="bg-white dark:bg-gray-900">
                {loading && (<div className="absolute bg-white inset-0 z-50 flex items-center justify-center">
                    <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-700"></div>
                </div>)}
                <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                    <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
                        <p className="mb-5 f text-blue-700  font-extrabold sm:text-xl dark:text-gray-400">Pro plan</p>
                        <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">Free prompts limit reached</h2>
                        <p className="mb-5 font-light text-gray-500 sm:text-xl dark:text-gray-400">The free version of generative.day let’s you create with $1 of ChatGPT credit</p>
                        <p className="mb-5 font-light text-gray-500 sm:text-xl dark:text-gray-400">Upgrade to keep rockin’!</p>
                    </div>
                    <div className="space-y-8 lg:grid lg:grid-cols-2 sm:gap-6 xl:gap-10 lg:space-y-0">
                        <div className="flex flex-col p-6  text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow-lg shadow-gray-400 dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white">
                            <h3 className="mb-4 text-2xl font-semibold">Billed yearly</h3>
                            <div className="flex justify-center items-baseline mt-8">
                                <span className="mr-2 text-5xl font-extrabold">$19</span>
                                <span className="text-gray-500 dark:text-gray-400">/month</span>
                            </div>
                            <p className="font-light text-gray-500 sm:text-lg dark:text-gray-400 mb-8">+ GPT fees*</p>
                            <button onClick={handleYearlyClick} className="text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:ring-blue-200 font-medium rounded-lg text-lg px-5 py-2.5 text-center dark:text-white  dark:focus:ring-blue-900 border border-blue-600">Get started</button>
                        </div>

                        <div className="flex flex-col p-6  text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white">
                            <h3 className="mb-4 text-2xl font-semibold">Billed monthly</h3>
                            <div className="flex justify-center items-baseline mt-8">
                                <span className="mr-2 text-5xl font-extrabold">$29</span>
                                <span className="text-gray-500 dark:text-gray-400">/month</span>
                            </div>
                            <p className="font-light text-gray-500 sm:text-lg dark:text-gray-400 mb-8">+ GPT fees*</p>
                            <button onClick={handleMonthlyClick} className="text-blue-500 bg-transparent hover:bg-blue-700 hover:text-white focus:ring-4 focus:ring-blue-200 font-medium rounded-lg text-lg px-5 py-2.5 text-center dark:text-white  dark:focus:ring-blue-900 border border-blue-600">Get started</button>
                        </div>
                        <p className="font-light text-gray-500 sm:text-xs dark:text-gray-400 mb-8">* ChatGPT fees are priced per $10 credit</p>
                    </div>
                </div>
            </section>
        </Modal.Body>
    </Modal>;
};