import { Dropdown } from "flowbite-react";
import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../firebase/context";
import { IConversation } from "../../../interfaces/interfaces";
import { createConversation } from "../../../services/ChatAPI";
import { HiOutlinePencilAlt, HiOutlineStar } from "react-icons/hi";
import { DeleteConversationModal } from "./DeleteConversationModal";
import { RenameConversationModal } from "./RenameConversationModal";
import { RxDiscordLogo } from "react-icons/rx";

interface LeftBarProps {
    conversations: IConversation[];
    showLeftBarInMobile: boolean;
    setShowLeftBarInMobile: (show: boolean) => void;
}

export const LeftBar = ({ conversations, showLeftBarInMobile, setShowLeftBarInMobile }: LeftBarProps) => {

    const { user } = useAuth();
    const navigate = useNavigate();

    const { conversationId } = useParams();

    const [showDeleteModal, setShowDeleteModal] = React.useState(false);
    const [showRenameModal, setShowRenameModal] = React.useState(false);
    const [selectedConversation, setSelectedConversation] = React.useState<IConversation | null>(null);

    const createNewConversation = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        const conversation = await createConversation(user?.uid as string);
        navigate(`/conversation/${conversation.uid}`);
        setShowLeftBarInMobile(false);
    }

    return (
        <nav className={` md:w-1/6  bg-gray-100 transition-width duration-300  h-full ease-in-out md:transition-none  overflow-hidden  shrink-0 ${showLeftBarInMobile ? 'w-5/6' : 'w-0 '}  md:block md:bg-white `}>
            {/* <div className="py-4 px-2">
                <div className="flex items-center  px-2 py-1 font-medium text-gray-700 hover:text-gray-900 focus:outline-none focus:shadow-outline-blue">
                    <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0zM10 7v3m0 0v3m0-3h3m-3 0H7"></path></svg>
                    <span className="ml-2">Search</span>
                </div>
                <div className="flex items-center px-2 py-1 font-medium text-gray-700 hover:text-gray-900 focus:outline-none focus:shadow-outline-blue">
                    <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                    <span className="ml-2">Updates</span>
                </div>
            </div> */}
            <div className="relative h-full">
                <div className="py-4 px-2">
                    <div className="flex justify-between items-center">
                        <h3 className="font-semibold  text-gray-800 px-2 py-1">Private</h3>
                        <button className="text-gray-600 hover:text-gray-800 focus:outline-none focus:shadow-outline-blue" onClick={createNewConversation} id="new-prompt-button">
                            <svg className="h-8 w-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9"></path></svg>
                        </button>
                    </div>

                    <ul className="mt-2 pl-3">
                        {conversations.map((conversation) => (
                            <li key={conversation.uid} className={`px-2 py-1 text-xs font-medium text-gray-700 hover:text-gray-900 flex justify-between items-center focus:outline-none focus:shadow-outline-blue ${conversationId === conversation.uid ? 'font-bold' : ''}`}>
                                <Link to={`/conversation/${conversation.uid}`} onClick={() => setShowLeftBarInMobile(false)} className="pr-2 prompt-link">
                                    {conversation.title}
                                </Link>
                                <Dropdown
                                    arrowIcon={false}
                                    inline={true}
                                    className="font-normal"
                                    label={<svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"></path></svg>}
                                >
                                    <Dropdown.Header>
                                        <span className="block text-sm">
                                            {conversation.title}
                                        </span>
                                    </Dropdown.Header>
                                    <Link to={`/conversation/${conversation.uid}`}>
                                        <Dropdown.Item>
                                            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"></path></svg>
                                            View
                                        </Dropdown.Item>
                                    </Link>
                                    {/* <Dropdown.Item>
                                        <HiOutlineStar />
                                        Add to favorites
                                    </Dropdown.Item> */}
                                    <Dropdown.Item onClick={() => {
                                        setSelectedConversation(conversation);
                                        // console.log(conversation, selectedConversation)
                                        setShowRenameModal(true);
                                    }}>
                                        <HiOutlinePencilAlt />
                                        Rename
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={() => {
                                        setSelectedConversation(conversation);
                                        // console.log(conversation, selectedConversation)
                                        setShowDeleteModal(true);
                                    }}>
                                        <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path></svg>
                                        Delete
                                    </Dropdown.Item>
                                </Dropdown>

                            </li>
                        ))}
                    </ul>
                </div>
                <div className="absolute bottom-0 pb-2 pl-2">
                    <a href="https://discord.gg/MpN8rXhu" className="flex items-center text-sm font-medium text-gray-700 hover:text-gray-900 focus:outline-none focus:shadow-outline-blue">
                        <RxDiscordLogo className="mr-1" />
                        Support
                    </a>
                </div>
            </div>
            {selectedConversation && <RenameConversationModal show={showRenameModal} setShow={setShowRenameModal} conversation={selectedConversation} setSelectedConversation={setSelectedConversation} />}
            {selectedConversation && <DeleteConversationModal show={showDeleteModal} setShow={setShowDeleteModal} conversation={selectedConversation} setSelectedConversation={setSelectedConversation} conversations={conversations} />}

        </nav>
    );
};