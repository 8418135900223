import React from "react";
import { Label } from "flowbite-react";
import Slider from "@mui/material/Slider";
import Tooltip from '@mui/material/Tooltip';
import { HiInformationCircle, HiOutlineInformationCircle } from "react-icons/hi";

interface FrequencyPenaltySliderProps {
    frequencyPenalty: number;
    setFrequencyPenalty: (frequencyPenalty: number) => void;
}

export const FrequencyPenaltySlider = ({ frequencyPenalty, setFrequencyPenalty }: FrequencyPenaltySliderProps) => {

    const STEP = 0.01;
    const MIN = 0;
    const MAX = 2;

    const tooltipText = "How much to penalize new tokens based on their existing frequency in the text so far. Decreases the model's likelihood to repeat the same line verbatim.";

    return (
        <div className="py-1 px-2">
            <div className="px-2 block flex items-center">
                <Label
                    htmlFor="frequencyPenalty"
                    value="Frequency penalty"
                    className="shrink-0"
                />
                <Tooltip title={tooltipText} placement='top' enterTouchDelay={0} arrow>
                    <div className="ml-2">
                        <HiInformationCircle className={"h-4 w-4"} />
                    </div>
                </Tooltip>
            </div>
            <Slider aria-label="FrequencyPenalty"
                min={MIN}
                max={MAX}
                step={STEP}
                value={frequencyPenalty}
                onChange={(e, value) => setFrequencyPenalty(value as number)}
                size="small"
                valueLabelDisplay="auto"
            />
        </div>
    );
}