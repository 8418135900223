import React, { useEffect, useRef, useState } from "react";
import { IPrompt } from "../../../interfaces/interfaces";
import ContentEditable, { ContentEditableEvent } from 'react-contenteditable'
import { Button, Spinner } from "flowbite-react";
import { updatePrompt } from "../../../services/ChatAPI";
import { ExamplePrompt } from "./ExamplePrompt";
import { useAuth } from "../../../firebase/context";
import { RiSettings3Line } from "react-icons/ri";

interface PromptProps {
    prompt: IPrompt;
    setPrompt: (prompt: IPrompt) => void;
    createAndNavigateToPrompt: (prompt?: IPrompt) => void;
    newPromptLoading: boolean;
    setNewPromptLoading: (loading: boolean) => void;
    onToggleRightBarInMobile: () => void;
}


export function Prompt({ prompt, setPrompt, createAndNavigateToPrompt, newPromptLoading, setNewPromptLoading, onToggleRightBarInMobile }: PromptProps) {


    const text = useRef(prompt.prompt || "");

    const promptRef = useRef<HTMLDivElement>(null);

    const { user } = useAuth();

    const [hadOutput, setHadOutput] = useState(false);

    const handlePromptChange = (evt: ContentEditableEvent) => {
        text.current = evt.target.value.replace(/<[^>]+>/g, '').replaceAll('&nbsp;', ' ');
        setPrompt({ ...prompt, prompt: text.current });
    }

    const handleMouseDown = (evt: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
        if (prompt.output) {
            let newPrompt = `${text.current}\n\n${prompt.output}`;
            text.current = newPrompt;
            setHadOutput(true);
            setPrompt({ ...prompt, prompt: text.current, output: '' });
            promptRef.current?.focus();
        }
    }

    const blogPostExample = {
        prompt: `Write a blog post about "Generative Day" (www.generative.day). Generative Day is a B2B SaaS enabling business to gain efficiency in their activity by using ChatGPT's OpenAI technology. It is currently in beta version on the individual plan only. It offers a visual editor into which professionals can prompt request to ChatGPT. It offers also example of prompts, with real-life business use cases, to demonstrate the possibilities of the tool.
        
        Do the following:
        
        1. Display in bullet points the plan of the article, which should include an introduction, 3 sections, and a conclusion
        2. Develop with 3 paragraphs the content of one of the section`,
        temperature: 0.7,
        topP: 1,
        maximumLength: 3800,
        frequencyPenalty: 0,
        presencePenalty: 0,
        bestOf: 1,
        owner: user?.uid as string,
        title: 'Blog Post Example'
    }

    const googleAdsExample = {
        prompt: `Write a Google Ads about "Generative Day". Generative Day is a B2B SaaS enabling business to gain efficiency in their activity by using ChatGPT's OpenAI technology. It is currently in beta version on the individual plan only. It offers a visual editor into which professionals can prompt request to ChatGPT. It offers also example of prompts, with real-life business use cases, to demonstrate the possibilities of the tool.Several items are required for this ads:1. Provide 3 headlines for the ad2. Provide 3 descriptions for the ad3. Provide 10 keywords for the ad`,
        temperature: 0.7,
        topP: 1,
        maximumLength: 3800,
        frequencyPenalty: 0,
        presencePenalty: 0,
        bestOf: 1,
        owner: user?.uid as string,
        title: 'Google Ads Example'
    }

    const facebookPostExample = {
        prompt: `Write a Facebook post about "Generative Day" (www.generative.day). Generative Day is a B2B SaaS enabling business to gain efficiency in their activity by using ChatGPT's OpenAI technology. It is currently in beta version on the individual plan only. It offers a visual editor into which professionals can prompt request to ChatGPT. It offers also example of prompts, with real-life business use cases, to demonstrate the possibilities of the tool.This post should target individual professionals willing to gain productivity with text generation. It should have a pro but friendly tone. It should contains emojis, as well as a link to the  website`,
        temperature: 0.7,
        topP: 1,
        maximumLength: 3800,
        frequencyPenalty: 0,
        presencePenalty: 0,
        bestOf: 1,
        owner: user?.uid as string,
        title: 'Facebook Post Example'
    }

    const linkedinPostExample = {
        prompt: `Write a LinkedIn post about "Generative Day" (www.generative.day). Generative Day is a B2B SaaS enabling business to gain efficiency in their activity by using ChatGPT's OpenAI technology. It is currently in beta version on the individual plan only. It offers a visual editor into which professionals can prompt request to ChatGPT. It offers also example of prompts, with real-life business use cases, to demonstrate the possibilities of the tool.This post should target individual professionals willing to gain productivity with text generation. It should have a pro but friendly tone. It should contains emojis, as well as a link to the  website`,
        temperature: 0.7,
        topP: 1,
        maximumLength: 3800,
        frequencyPenalty: 0,
        presencePenalty: 0,
        bestOf: 1,
        owner: user?.uid as string,
        title: 'LinkedIn Post Example'
    }

    const haikuExample = {
        prompt: `Write a haïku about "Generative Day" (www.generative.day). Generative Day is a B2B SaaS enabling business to gain efficiency in their activity by using ChatGPT's OpenAI technology. It is currently in beta version on the individual plan only. It offers a visual editor into which professionals can prompt request to ChatGPT. It offers also example of prompts, with real-life business use cases, to demonstrate the possibilities of the tool.`,
        temperature: 0.7,
        topP: 1,
        maximumLength: 3800,
        frequencyPenalty: 0,
        presencePenalty: 0,
        bestOf: 1,
        owner: user?.uid as string,
        title: 'Haiku Example'
    }

    const landingPageExample = {
        prompt: `Generative Day  is a B2B SaaS enabling business to gain efficiency in their activity by using ChatGPT's OpenAI technology. It is currently in beta version on the individual plan only. It offers a visual editor into which professionals can prompt request to ChatGPT. It offers also example of prompts, with real-life business use cases, to demonstrate the possibilities of the tool.Write a landing page for Generative Day with the following information:
        1. An url for the landing page
        2. A hero headline
        3. A mission statement to put under the hero headline
        4. A first section headline featuring the value of the product
        4bis. A paragraph description for that section
        5. A second section headline featuring the value of the product
        5bis. A paragraph description for that section
        6. A second section headline featuring the value of the product
        6bis. A paragraph description for that section`,
        temperature: 0.7,
        topP: 1,
        maximumLength: 3800,
        frequencyPenalty: 0,
        presencePenalty: 0,
        bestOf: 1,
        owner: user?.uid as string,
        title: 'Landing Page Example'
    }

    const meetingNotesExample = {
        prompt: `Generative Day  is a B2B SaaS enabling business to gain efficiency in their activity by using ChatGPT's OpenAI technology. It is currently in beta version on the individual plan only. It offers a visual editor into which professionals can prompt request to ChatGPT. It offers also example of prompts, with real-life business use cases, to demonstrate the possibilities of the tool.
        
        After a meeting with the CEO, our product manager took the following notes of the meeting:
            - Q1 2023: launch an "invite members" feature
            - Add more real-life business examples
            - Iterate every week with the tech and design team
            
            Provide a long version of thoses meetings note to share with the team`,
        temperature: 0.7,
        topP: 1,
        maximumLength: 3800,
        frequencyPenalty: 0,
        presencePenalty: 0,
        bestOf: 1,
        owner: user?.uid as string,
        title: 'Meeting Notes Example'
    }

    useEffect(() => {
        text.current = prompt.prompt || "";
    }, [prompt.prompt])

    const handleSubmit = () => {
        if (((prompt.output && prompt.output.length > 0) || hadOutput) && prompt.prompt && prompt.prompt.length > 0) {
            console.log('create prompt')

            createAndNavigateToPrompt({ ...prompt, output: '' });
            setHadOutput(false);
        } else {
            console.log('update prompt')
            updatePrompt({...prompt, output: ''});
        }
        setNewPromptLoading(true);
    }

    const handleExampleClick = (prompt: IPrompt) => {
        setHadOutput(false);
        // createAndNavigateToPrompt(prompt);
        createAndNavigateToPrompt({...prompt, output: ''});
    }

    return (
        <div className="w-full md:w-4/6 px-4 h-full shrink-0 flex flex-col  justify-around relative">
            <div className="flex gap-2 items-center overflow-x-auto scrollbar-hidden">
                <strong className="text-gray-600 font-bold text-sm">Examples</strong>
                <ExamplePrompt prompt={blogPostExample} setCurrentPrompt={setPrompt} currentPrompt={prompt} title="📄 Example blog post"  handleClick={handleExampleClick}  />
                <ExamplePrompt prompt={googleAdsExample} setCurrentPrompt={setPrompt} currentPrompt={prompt} title="🔍 Google Ads"  handleClick={handleExampleClick} />
                <ExamplePrompt prompt={facebookPostExample} setCurrentPrompt={setPrompt} currentPrompt={prompt} title="📣 Facebook post"  handleClick={handleExampleClick} />
                <ExamplePrompt prompt={linkedinPostExample} setCurrentPrompt={setPrompt} currentPrompt={prompt} title="💼 LinkedIn post"  handleClick={handleExampleClick} />
                <ExamplePrompt prompt={haikuExample} setCurrentPrompt={setPrompt} currentPrompt={prompt} title="🌊 Haïku"  handleClick={handleExampleClick} />
                <ExamplePrompt prompt={landingPageExample} setCurrentPrompt={setPrompt} currentPrompt={prompt} title="🛬 Landing page"  handleClick={handleExampleClick} />
                <ExamplePrompt prompt={meetingNotesExample} setCurrentPrompt={setPrompt} currentPrompt={prompt} title="⏰ Meeting notes" handleClick={handleExampleClick}  />
                {/* <ExamplePrompt prompt={} setCurrentPrompt={setPrompt} /> */}
                {/* <ExamplePrompt prompt={} setCurrentPrompt={setPrompt} /> */}
                {/* <ExamplePrompt prompt={} setCurrentPrompt={setPrompt} /> */}
            </div>
            <div className="w-full h-3/4 overflow-auto flex-1 text-sm text-gray-800 border border-gray-300 p-4 my-4 resize-none"

            >
                <div onMouseDown={handleMouseDown} onTouchStart={handleMouseDown} >
                    <ContentEditable
                        html={prompt.prompt || ""}
                        innerRef={promptRef}
                        onChange={handlePromptChange}
                        className="focus:outline-none  inline-block w-full whitespace-pre-wrap mb-4"
                    />
                    <p className="focus:outline-none  inline-block w-full inline bg-green-200 hover:bg-blue-200 whitespace-pre-wrap">
                        {prompt.output}
                    </p>
                </div>
            </div>

            <div className="my-4 flex justify-between">
                <Button onClick={handleSubmit} disabled={newPromptLoading || !prompt.prompt || prompt.prompt.length < 0} gradientDuoTone="cyanToBlue" outline>
                    {newPromptLoading ? <Spinner aria-label="" /> : "Submit"}
                </Button>

                <Button onClick={onToggleRightBarInMobile} gradientDuoTone="cyanToBlue" className="md:hidden" outline >
                    <RiSettings3Line size={"20px"} />
                </Button>
            </div>
        </div>
    );

}