import React from "react";
import { Label } from "flowbite-react";
import Slider from "@mui/material/Slider";
import Tooltip from '@mui/material/Tooltip';
import { HiInformationCircle, HiOutlineInformationCircle } from "react-icons/hi";

interface PresencePenaltySliderProps {
    presencePenalty: number;
    setPresencePenalty: (presencePenalty: number) => void;
}

export const PresencePenaltySlider = ({ presencePenalty, setPresencePenalty }: PresencePenaltySliderProps) => {

    const STEP = 0.01;
    const MIN = 0;
    const MAX = 2;


    const tooltipText = "How much to penalize new tokens based on whether they appear in the text so far. Increases the model's likelihood to talk about new topics.";


    return (
        <div className="py-1 px-2">
            <div className="px-2 block flex items-center">
                <Label
                    htmlFor="presencePenalty"
                    value="Presence penalty"
                    className="shrink-0"
                />
                <Tooltip title={tooltipText} placement='top' enterTouchDelay={0} arrow>
                    <div className="ml-2">
                        <HiInformationCircle />
                    </div>
                </Tooltip>
            </div>
            <Slider aria-label="PresencePenalty"
                min={MIN}
                max={MAX}
                step={STEP}
                value={presencePenalty}
                onChange={(e, value) => setPresencePenalty(value as number)}
                size="small"
                valueLabelDisplay="auto"
            />
        </div>
    );
}