import { Button, Modal } from "flowbite-react";
import React from "react";
import { HiOutlineExclamationCircle } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { IPrompt } from "../../../interfaces/interfaces";
import { deletePrompt } from "../../../services/ChatAPI";

interface DeletePromptModalProps {
    show: boolean;
    setShow: (show: boolean) => void;
    prompt: IPrompt;
    prompts: IPrompt[];
    setSelectedPrompt: (prompt: IPrompt | null) => void;
}


export const DeletePromptModal = ({ show, setShow, prompt, prompts, setSelectedPrompt }: DeletePromptModalProps) => {

    const navigate = useNavigate();

    const onClose = () => {
        console.log("Close");
        setShow(false);
    };

    const onClickDelete = async () => {
        console.log("Click");
        await deletePrompt(prompt);
        setShow(false);
        navigate("/prompt");
        setSelectedPrompt(null);
    };

    return (
        <Modal
            show={show}
            size="md"
            popup={true}
            onClose={onClose}
        >
            <Modal.Header />
            <Modal.Body>
                <div className="text-center">
                    <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
                    <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                        Are you sure you want to delete this prompt?
                    </h3>
                    <div className="flex justify-center gap-4">
                        <Button
                            color="failure"
                            onClick={onClickDelete}
                        >
                            Yes, I'm sure
                        </Button>
                        <Button
                            color="gray"
                            onClick={onClose}
                        >
                            No, cancel
                        </Button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>);
};