import React from "react";
import { Label } from "flowbite-react";
import Slider from "@mui/material/Slider";
import Tooltip from '@mui/material/Tooltip';
import { HiInformationCircle, HiOutlineInformationCircle } from "react-icons/hi";

interface MaximumLengthSliderProps {
    maximumLength: number;
    setMaximumLength: (maximumLength: number) => void;
}

export const MaximumLengthSlider = ({ maximumLength, setMaximumLength }: MaximumLengthSliderProps) => {

    const STEP = 1;
    const MIN = 0;
    const MAX = 4000;

    const tooltipText = "The maximum number of tokens to generate. Requests can use up to 2,048 or 4,000 tokens shared between prompt and completion. One token is roughly 4 characters for normal English text.";


    return (
        <div className="py-1 px-2">
            <div className="px-2 block flex items-center">
                <Label
                    htmlFor="maximumLength"
                    value="Maximum Length"
                    className="shrink-0"
                />
                <Tooltip title={tooltipText} placement='top' enterTouchDelay={0} arrow>
                    <div className="ml-2">
                        <HiInformationCircle />
                    </div>
                </Tooltip>
            </div>
            <Slider aria-label="MaximumLength"
                min={MIN}
                max={MAX}
                step={STEP}
                value={maximumLength}
                onChange={(e, value) => setMaximumLength(value as number)}
                size="small"
                valueLabelDisplay="auto"
            />
        </div>
    );
}