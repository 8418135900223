import firebaseApp from '../firebase';
import { getFirestore, collection, doc, getDoc, setDoc, query, where, onSnapshot, orderBy, serverTimestamp, deleteDoc } from "firebase/firestore";
import { IChatMessage, IConversation, IPrompt } from '../interfaces/interfaces';

const db = getFirestore(firebaseApp);


export async function createConversation(userId: string): Promise<IConversation> {
    const conversationRef = doc(collection(db, 'conversations'));
    const conversation = { owner: userId, title: 'Untitled', createdAt: serverTimestamp() };
    await setDoc(conversationRef, conversation);

    return { uid: conversationRef.id, ...conversation };
}


export function getConversationsByOwner(userId: string, callback: (conversations: IConversation[]) => void, onLoaded: () => void) {
    const conversationsRef = collection(db, 'conversations');
    const q = query(conversationsRef, where("owner", "==", userId), orderBy("createdAt", "desc"));

    return onSnapshot(q, (querySnapshot) => {
        const conversations: IConversation[] = [];
        querySnapshot.forEach((doc) => {
            conversations.push({ uid: doc.id, ...doc.data() } as IConversation);
        });
        callback(conversations);
        onLoaded();
    });
}
// export function getConversationsByOwner(userId: string, callback: (conversations: IConversation[]) => void) {
//     const conversationsRef = collection(db, 'conversations');
//     const q = query(conversationsRef, where("owner", "==", userId));

//     return onSnapshot(q, (querySnapshot) => {
//         const conversations: IConversation[] = [];
//         querySnapshot.forEach((doc) => {
//             conversations.push({ uid: doc.id, ...doc.data() } as IConversation);
//         });
//         console.log(conversations)
//         return callback(conversations);
//     });
// }

export function getMessagesByConversation(conversationId: string, callback: (messages: IChatMessage[]) => void) {
    const messagesRef = collection(db, 'conversations', conversationId, 'messages');
    const q = query(messagesRef, orderBy("createdAt"));

    return onSnapshot(q, (querySnapshot) => {
        const messages: IChatMessage[] = [];
        querySnapshot.forEach((doc) => {
            messages.push({ uid: doc.id, ...doc.data() } as IChatMessage);
        });
        return callback(messages);
    });
}

export async function createPrompt(prompt: IPrompt): Promise<IPrompt> {
    const promptRef = doc(collection(db, 'prompts'));
    await setDoc(promptRef, { createdAt: serverTimestamp(), ...prompt });

    return { uid: promptRef.id, ...prompt };
}

export async function updatePrompt(prompt: IPrompt): Promise<void> {
    if(!prompt.uid) {
        throw new Error('Prompt must have an id');
    }
    // if(prompt.output && prompt.output.length > 0) {
    //     const { uid, output, createdAt, ...promptWithoutOutput } = prompt;
    //     await createPrompt(promptWithoutOutput);
    //     return;
    // }
    const promptRef = doc(db, 'prompts', prompt.uid);
    await setDoc(promptRef, { ...prompt, updatedAt: serverTimestamp() });
}

export async function deletePrompt(prompt: IPrompt): Promise<void> {
    if(!prompt.uid) {
        throw new Error('Prompt must have an id');
    }
    const promptRef = doc(db, 'prompts', prompt.uid);
    await deleteDoc(promptRef);
}

export function getPromptsByOwner(userId: string, callback: (prompts: IPrompt[]) => void, onLoaded: (lastPrompt: IPrompt) => void) {
    const promptsRef = collection(db, 'prompts');
    const q = query(promptsRef, where("owner", "==", userId), orderBy("createdAt", "desc"));

    return onSnapshot(q, (querySnapshot) => {
        const prompts: IPrompt[] = [];
        querySnapshot.forEach((doc) => {
            prompts.push({ uid: doc.id, ...doc.data() } as IPrompt);
        });
        callback(prompts);
        const lastPrompt = prompts[0];
        onLoaded(lastPrompt);
    });
}

export function watchRefreshTokensNotifications(userId: string, callback: () => void) {
    const notificationsRef = collection(db, 'notifications');
    const q = query(notificationsRef, where("userId", "==", userId), where("type", "==", "refresh_token"));

    return onSnapshot(q, (querySnapshot) => {
        querySnapshot.forEach((doc) => {
            callback();
        });
    });
}

export function watchTokens(userId: string, callback: (tokens: number) => void) {
    const tokensRef = doc(collection(db, 'tokens'), userId);

    return onSnapshot(tokensRef, (doc) => {
        console.log(doc)
        if (doc.exists()) {
            // console.log(doc.data().tokens)
            return callback(doc.data().tokens);
        }
        return callback(0);
    });
}

export const launchSubscription = async (userId: string, type: 'yearly' | 'monthly') => {
    const docRef = doc(collection(db, 'customers', userId, 'checkout_sessions'));

    // console.log("docRef", docRef)
    // console.log("process.env.REACT_APP_STRIPE_INDIVIDUAL_PLAN_PRICE_ID", process.env.REACT_APP_STRIPE_INDIVIDUAL_PLAN_PRICE_ID)
    // console.log("process.env.REACT_APP_STRIPE_GPT_CREDIT_PRICE_ID", process.env.REACT_APP_STRIPE_GPT_CREDIT_PRICE_ID)
    // console.log("window.location.origin", window.location.origin)
    await setDoc(docRef, {
        line_items: [
            { price: type === 'yearly' ? process.env.REACT_APP_STRIPE_PRO_PLAN_YEARLY_PRICE_ID : process.env.REACT_APP_STRIPE_PRO_PLAN_MONTHLY_PRICE_ID, quantity: 1 },
            { price: process.env.REACT_APP_STRIPE_GPT_CREDIT_PRICE_ID, quantity: 1 }
        ],
        success_url: window.location.origin,
        cancel_url: window.location.origin,
    });
    // Wait for the CheckoutSession to get attached by the extension
    onSnapshot(docRef, (snap) => {
        const { error, url } = snap.data() as any;
        if (error) {
            alert(`An error occured: ${error.message}`);
        }
        if (url) {
            // We have a Stripe Checkout URL, let's redirect.
            window.location.assign(url);
        }
    });
}

export async function postMessage(conversationId: string, message: IChatMessage): Promise<string> {
    const messageRef = doc(collection(db, 'conversations', conversationId, 'messages'));
    await setDoc(messageRef, { ...message, createdAt: serverTimestamp() });
    return messageRef.id;
}

export async function getConversation(id?: string): Promise<any> {
    const conversationRef = doc(collection(db, 'conversations'), id);

    const conversation = await getDoc(conversationRef);
    if (conversation.exists()) {
        return conversation.data();
    }
    return null;
}

export async function deleteConversation(id: string): Promise<void> {
    // First we need to delete its messages subcollection
    // const messagesRef = collection(db, 'conversations', id, 'messages');
    // const q = query(messagesRef);
    // const querySnapshot = await getDocs(q);
    // querySnapshot.forEach(async (doc) => {
    //     await deleteDoc(doc.ref);
    // });
    const conversationRef = doc(collection(db, 'conversations'), id);
    await deleteDoc(conversationRef);
}

export async function updateConversation(id: string, conversation: IConversation): Promise<void> {
    const conversationRef = doc(collection(db, 'conversations'), id);
    await setDoc(conversationRef, conversation);
}

// export async function postPrompt(prompt: string): Promise<string> {
//     const response = await fetch(`${process.env.REACT_APP_BACKEND_URI}/prompt`, {
//         method: 'POST',
//         headers: {
//             'Content-Type': 'application/json',
//         },
//         credentials: 'include',
//         body: JSON.stringify({ prompt }),
//     });
//     return response.json();
// }