import { User as FirebaseUser } from 'firebase/auth';
import { Dropdown, Navbar } from 'flowbite-react';
import Avatar from '@mui/material/Avatar';
import React from 'react';
import { useAuth } from '../../firebase/context';


export interface IHeaderUserButtonProps {
    user: FirebaseUser;
    logout: () => void;
}

export const HeaderUserButton = ({ user, logout }: IHeaderUserButtonProps) => {

    const { redirectToBillingSettings, isFreeTier } = useAuth();

    const showBillingPortal = () => {
        redirectToBillingSettings();
    }

    return (

        <div className="flex md:order-2">
            <Dropdown
                arrowIcon={true}
                inline={true}
                label={
                    <div className='flex items-center'>
                        <Avatar alt="User settings" id="dropdown-user-menu"  src={user.photoURL ?? undefined} imgProps={{ referrerPolicy: "no-referrer" }} />
                        <span className='text-gray-600 text-sm pl-3'>{user.displayName}</span>
                    </div>
                }
            >
                <Dropdown.Header>
                    <span className="block text-sm">
                        {user?.displayName}
                    </span>
                    <span className="block truncate text-sm font-medium">
                        {user?.email}
                    </span>
                </Dropdown.Header>
                {!isFreeTier && (<>
                    <Dropdown.Item id="billing-link" onClick={showBillingPortal}>
                        Billing
                    </Dropdown.Item>
                    <Dropdown.Divider />
                </>)}

                <Dropdown.Item id="logout-link" onClick={logout}>
                    Sign out
                </Dropdown.Item>
            </Dropdown>
            {/* <Navbar.Toggle /> */}
        </div>
    );
};