import { Button } from 'flowbite-react';
import React from 'react';
import { IPrompt } from '../../../interfaces/interfaces';

interface ExamplePromptProps {
    prompt: IPrompt;
    setCurrentPrompt: (prompt: IPrompt) => void;
    title: string;
    currentPrompt: IPrompt;
    handleClick: (prompt: IPrompt) => void;
}


export const ExamplePrompt = ({ title, prompt, setCurrentPrompt, currentPrompt, handleClick }: ExamplePromptProps) => {

    
    return (
        <Button
            outline={true}
            gradientDuoTone="cyanToBlue"
            onFocus={(e) => e.target.blur()}
            size="xs"
            className='shrink-0'
            onClick={() => handleClick({ doNotUpdate: true, ...prompt })}
        >
            {title}
        </Button>
    );
};