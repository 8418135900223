import React from "react";
import { Label } from "flowbite-react";
import Slider from "@mui/material/Slider";
import Tooltip from '@mui/material/Tooltip';
import { HiInformationCircle, HiOutlineInformationCircle } from "react-icons/hi";

interface TopPSliderProps {
    topP: number;
    setTopP: (topP: number) => void;
}

export const TopPSlider = ({ topP, setTopP }: TopPSliderProps) => {

    const STEP = 0.01;
    const MIN = 0;
    const MAX = 1;

    const tooltipText = "Controls diversity via nucleus sampling: 0.5 means half of all likelihood-weighted options are considered.";


    return (
            <div className="py-1 px-2">
                <div className="px-2 block flex items-center">
                    <Label
                        htmlFor="topP"
                        value="Top P"
                        className="shrink-0"
                    />
                    <Tooltip title={tooltipText} placement='top' enterTouchDelay={0} arrow>
                        <div className="ml-2">
                            <HiInformationCircle />
                        </div>
                    </Tooltip>
                </div>
                <Slider aria-label="TopP"
                    min={MIN}
                    max={MAX}
                    step={STEP}
                    value={topP}
                    onChange={(e, value) => setTopP(value as number)}
                    size="small"
                    valueLabelDisplay="auto"
                />
            </div>
    );
}