import { Button, Modal, TextInput } from "flowbite-react";
import React, { useEffect, useState } from "react";
import { HiOutlineExclamationCircle } from "react-icons/hi";
import { IConversation } from "../../../interfaces/interfaces";
import { updateConversation } from "../../../services/ChatAPI";

interface RenameConversationModalProps {
    show: boolean;
    setShow: (show: boolean) => void;
    conversation: IConversation;
    setSelectedConversation: (conversation: IConversation | null) => void;
}


export const RenameConversationModal = ({ show, setShow, conversation, setSelectedConversation }: RenameConversationModalProps) => {


    const [newTitle, setNewTitle] = useState<string>("");

    useEffect(() => {
        setNewTitle(conversation!.title || "");
    }, [conversation]);

    const onClose = () => {
        console.log("Close");
        setShow(false);
    };

    const onClickRename = async () => {
        console.log("Click");
        await updateConversation(conversation!.uid, { ...conversation, title: newTitle } as IConversation);
        setShow(false);
        setSelectedConversation(null);
    };

    return (
        <Modal
            show={show}
            size="md"
            popup={true}
            onClose={onClose}
        >
            <Modal.Header />
            <Modal.Body>
                <div className="text-center">
                    <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
                    <h3 className=" text-lg font-normal text-gray-500 dark:text-gray-400">
                        Do you want to rename this conversation?
                    </h3>
                    <div className="py-2">
                        {/* <div className="mb-2 block">
                            <Label
                                htmlFor="title"
                                value="Title:"
                            />
                        </div> */}
                        <TextInput
                            id="email"
                            placeholder="Type your title here..."
                            required={true}
                            value={newTitle}
                            onChange={e => setNewTitle(e.target.value)}
                        />
                    </div>
                    <div className="flex justify-center gap-4">
                        <Button
                            // color="success"
                            onClick={onClickRename}
                        >
                            Rename conversation
                        </Button>
                        <Button
                            color="gray"
                            onClick={onClose}
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
            </Modal.Body>
        </Modal >);
};