import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Chat } from "./Chat/Chat";
import { Header } from "../Header/Header";
import { LeftBar } from "./LeftBar/LeftBar";
import { RightBar } from "./RightBar/RightBar";
import { createConversation, getConversationsByOwner } from "../../services/ChatAPI";
import { IConversation } from "../../interfaces/interfaces";
import { useAuth } from "../../firebase/context";
import { useTitle } from "react-use";
import { PricingModal } from "../PricingModal/PricingModal";

export function ConversationPage() {

    const { conversationId } = useParams();
    const navigate = useNavigate();

    const { user, loading, showPricingModal } = useAuth();


    const [conversations, setConversations] = useState<IConversation[]>([]);
    const [currentConversation, setCurrentConversation] = useState<IConversation>();


    const [conversationsLoading, setConversationsLoading] = useState(true);



    useEffect(() => {
        async function createAndNavigateToConversation() {
            const conversation = await createConversation(user?.uid as string);
            navigate(`/conversation/${conversation.uid}`);
            setCurrentConversation(conversation);
        }

        async function updateCurrentConversation() {
            if (conversations.length === 0 && !conversationsLoading) {
                await createAndNavigateToConversation();
            } else if (!conversationId && conversations.length > 0 && !conversationsLoading) {
                navigate(`/conversation/${conversations[0].uid}`);
                setCurrentConversation(conversations[0]);
            } else if (!conversationsLoading) {
                const conversation = conversations.find(c => c.uid === conversationId);
                setCurrentConversation(conversation);
            }
        }

        updateCurrentConversation();
    }, [conversationId, conversations, conversationsLoading]);

    useTitle(currentConversation?.title + ' - GenerativeDay' || "Chat - GenerativeDay");

    // Callback function to set the conversationsLoading state to false when the conversations are done conversationsLoading
    const onConversationsLoaded = () => setConversationsLoading(false);

    useEffect(() => {
        const unsubscribe = getConversationsByOwner(user?.uid as string, setConversations, onConversationsLoaded);
        return () => unsubscribe();
    }, []);

    const [showLeftBarInMobile, setShowLeftBarInMobile] = useState(false);

    const onToggleLeftBarInMobile = () => {
        setShowLeftBarInMobile(!showLeftBarInMobile);
    }

    return (<>
        <Header onToggleLeftBarInMobile={onToggleLeftBarInMobile} />
        <main className="flex min-h-0 h-full flex-grow overflow-x-hidden">
            {showPricingModal && <PricingModal />}
            {(currentConversation && !loading) ? (<>
                <LeftBar conversations={conversations} showLeftBarInMobile={showLeftBarInMobile} setShowLeftBarInMobile={setShowLeftBarInMobile} />
                <Chat conversation={currentConversation} />
                <RightBar />
            </>) : <div className="w-full  h-full shrink-0 flex flex-col justify-between relative">
                <div className="flex-grow flex items-center justify-center">
                    <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-blue-500"></div>
                </div>
            </div>}

        </main>
    </>);
}